<template lang="html">
  <div class="ingredients" v-if="initialData.items">
    <div
      class="ingredients__item"
      v-for="(ingredient, i) in initialData.items"
      :key="i"
    >
      <div class="ingredients__item-image">
        <img
          v-if="ingredient.fields.image"
          :src="ingredient.fields.image.sizes.medium"
          :alt="ingredient.fields.image.alt"
        />
        <DummyImage v-else />
      </div>
      <div class="ingredients__item-quantity" v-if="ingredient.quantity">
        {{ ingredient.quantity }}
      </div>
      <div class="ingredients__item-title" v-if="ingredient.title">
        {{ ingredient.title }}
      </div>
    </div>
  </div>
</template>

<script>
import DummyImage from '@/components/DummyImage';
export default {
  name: 'Ingredients',
  components: { DummyImage },
  props: {
    initialData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.ingredients {
  display: flex;
  overflow-x: auto;
  overflow-y: visible;
  margin-right: -$containerPadding;
  padding-right: $containerPadding;

  &__item {
    &:not(:last-child) {
      margin-right: 10px;
    }

    &-quantity {
      @include tcl-big-text;
      color: $orange;
      margin-bottom: 5px;
      word-break: break-word;
    }

    &-title {
      @include tcl-big-text;
      word-break: break-word;
    }

    &-image {
      width: 82px;
      height: 82px;
      margin-bottom: 10px;
      border-radius: 5px;
      overflow: hidden;
      img {
      }
    }
  }
}
</style>
